body {
  font-family: sans-serif;
  font-size: 12px !important;
}

.header-brand-img1 {
  line-height: 2rem;
  vertical-align: middle;
  width: 214px;
}

.filterRow {
  margin-right: 10px;
}

.filterDesignFix {
  justify-content: flex-end;
  padding-top: 10px;
  min-height: 61px;
  padding-right: 0px !important;
  margin-left: 18px;
}


.form-inline {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -ms-flex-align: center;
  align-items: center;
}

.header .form-inline {
  margin-right: 7px;
}

.form-inline .form-check {
  width: 100%;
}

/* Modal panel styling */
.modal-panel {
  max-width: 100%;
  max-height: 90vh;
  overflow-y: auto;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Modal content styling */
.modal-content {
  padding: 1.5rem;
  overflow-y: auto;
}

/* Title and close icon styling */
.modal-title {
  margin-top: -1.5rem;
  margin-bottom: -0.5rem;
  font-weight: 600;
  color: #111827;
  font-size: 16px;
}

.close-icon {
  cursor: pointer;
  color: gray;
  transition: color 0.2s;
}

.close-icon:hover {
  color: black;
}

/* custom dialog panel */

.custom-panel {

  position: relative;
  /* equivalent to relative */
  transform: translateZ(0);
  /* equivalent to transform */
  /* overflow-y: auto;         equivalent to overflow-y */
  border-radius: 0.5rem;
  /* equivalent to rounded-lg */
  background-color: #ffffff;
  /* equivalent to bg-white */
  text-align: left;
  /* equivalent to text-left */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  /* equivalent to shadow-xl */
  transition: all 0.3s ease;
  /* equivalent to transition-all */
  width: 100%;
  /* equivalent to w-full */
  max-width: 80rem;
  /* equivalent to max-w-7xl */
  max-height: 94vh;
  /* equivalent to max-h-screen */
}

.scroll-bar-y {
  overflow-y: auto;
}

/* Divider styling */
.divider {
  width: 100%;
  border-bottom: 1px solid #d1d5db;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
}

/* Info section styling */
.info-section {
  margin-bottom: 1rem;
  border: 1px solid cadetblue;
  border-radius: 4px;
}

.section-header {
  color: white;
  font-size: 0.75rem;
  font-weight: 600;
  padding: 0.5rem;
}

.case-detail {
  height: calc(94vh - 100px);
  overflow-y: auto;
}

.section-content {
  padding: 0.5rem;
}

.info-row {
  display: flex;
  align-items: center;
  padding: 0.25rem 0;
}

.label {
  font-size: 0.7rem;
  font-weight: 600;
  color: #444;
  margin-right: 0.5rem;
}

.value {
  font-size: 0.7rem;
  font-weight: 550;
  color: #666;
  word-break: break-all;
}

/* Responsive styling */
@media (max-width: 768px) {
  .modal-panel {
    width: 100%;
  }

  .info-section {
    border: none;
    margin-bottom: 1rem;
  }
}

@media (max-width: 1297px) {
  .name-custom-class {
    width: 65%!important;
  }
}
@media (max-width: 1171px) {
  .custom-dr-class {
    width: 25%!important;
  }

  .custom-dr1-class {
    width: 25%!important;
  }
}

@media (max-width: 986px) {
  .custom-dr-class {
    width: 95%!important;
  }

  .custom-dr1-class {
    width: 18%!important;
  }

  .custom-BeginingDate-class{
    width: 50%!important;
  }

  .custom-dr1-class {
    width: 100%!important;
  }

}

@media (max-width: 770px) {
  .name-custom-class {
    width: 100%!important;
  }

  .custom-dr1-class {
    width: 100%!important;
  }

  .custom-about-text{
    width: 100%!important;
  }

}

@media (max-width: 640px) {
  .signdata-custom{
    width: 80%!important;
  }

  .lower-dr-class{
    width: 80%!important;
  }

  .my-hand-class{
    width: 100%!important;
  }
}


.rc-tree-treenode {
  font-size: 12px;
}

.rc-tree .rc-tree-treenode {
  margin: 0;
  padding: 0;
  line-height: 24px;
  white-space: nowrap;
  list-style: none;
  outline: 0;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: flex-start;
    justify-content: left;
    margin-bottom: 0;
  }

  .form-inline .form-group {
    /* display: flex; */
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    text-align: left;
    margin-bottom: 0;
  }

  .form-inline .form-control {
    display: inline-block;
    width: auto;
  }

  .form-inline .form-control-plaintext {
    display: inline-block;
  }

  .form-inline .input-group,
  .form-inline .form-select {
    width: auto;
  }

  .form-inline .form-check {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }

  .form-inline .form-check-input {
    position: relative;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }

  .form-inline .custom-control {
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.pagination {
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 3px;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  background-color: #fff;
  border: 1px solid #dadde1;
  color: #282f53;
}

.page-link:hover {
  z-index: 2;
  text-decoration: none;
  background-color: var(--primary005);
}

.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: none;
}

.page-link:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.page-item:last-child .page-link {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff !important;
}

.rs-picker-default .rs-picker-toggle {
  border-radius: 0px;
}

.page-item.disabled .page-link {
  color: #ced4da;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dadde1;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.3rem;
  font-size: 1.125rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}

.totalCount h6 {
  font-weight: 800;
}

.totalCount {
  width: 10%;
  float: left;
  text-align: left;
}

@media (max-width: 769px) {
  .button-hidden {
    display: none;
  }
}

@media (min-width: 769px) {
  .show-bar {
    display: none;
  }

  .patient-list {
    padding-left: 30px;
  }
}

@media (min-width: 550px) and (max-width: 769px) {

  .logo-width {
    width: 300px;
  }

}

@media (min-width: 481px) and (max-width: 550px) {
  .logo-width {
    width: 192px;
  }
}

@media (min-width: 320px) and (max-width: 481px) {
  .logo-width {
    width: 192px;
  }

  .font-size {
    font-size: 15px;
    margin-left: 10px;
  }
}

@media (max-width: 768px) {
  .custom-mb {
    margin-bottom: 10px;
  }
}

.modalGrid {
  display: grid;
  gap: 5px;
  grid-template-columns: 40% 59%;
}

.responsive-img {
  max-height: 80vh;
  min-height: 200px;
  object-fit: contain;
}

@media (max-width: 768px) {
  .responsive-img {
    max-height: 60vh;
    /* Adjust max height for smaller screens */
  }
}

@media (max-width: 480px) {
  .responsive-img {
    max-height: 50vh;
    /* Further adjust max height for very small screens */
  }
}


@media screen and (max-height: 799px) {

  /* .virtualHight{
      max-height: 64vh!important;
      min-height: 64vh !important;
  } */
  .pcard {
    margin-bottom: 0px;
    min-height: 88vh;
    max-height: 88vh;
  }

  .page-main {
    min-height: 90vh;
    max-height: 90vh;
  }

}

@media (min-height: 800px) and (max-height: 1000px) {
  .virtualHight {
    max-height: 68vh !important;
  }

  .pcard {
    margin-bottom: 0px;
    min-height: 90vh;
    max-height: 90vh;
  }

  .page-main {
    min-height: 92vh;
    max-height: 92vh;
  }
}

.pcard .card h6 {
  font-size: 11px !important;
  font-weight: 700 !important;
  line-height: 12px !important;
}

.pcard .card h2 {
  font-size: 9px;
  line-height: 1;
  color: white;
}

.pcard .card h6 {
  color: white;
}

.repCards .card-body {
  min-height: 144px;
  max-height: 144px;
}

.rs-date-range-input {
  font-size: 12px;
}

.badge {
  display: inline-block;
  padding: 3px 5px 4px;
  line-height: 1;
  color: #232222;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 3px;
  font-size: 10px;
  font-weight: 500;
  background-color: #dadde1;
}